import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You will always reap what you sow. If you plant apple seeds, you will get apple
trees. If you plant apple seeds, you will not get oranges. There is a direct
connection between what you put in and what you get out.`}</p>
    <p>{`There is always the agency of others at play and general misfortune, however:`}</p>
    <ol>
      <li parentName="ol">{`You will always be better off if you planted the seeds than if you didn't.
Do not let ideas of "wasted work" or "lost time" fool you into thinking like
a victim; trick you into not planting seeds, when your given expectations
aren't met. Continue in faith and look for the fruits that did yield, for
they are precisely the fruits that you need. Every failed attempt is a
learning opportunity. The obstacle is the way.`}</li>
      <li parentName="ol">{`Given eternity, all things will be made just. You will reap what you sow, it
is an eternal law in which God's hands are bound. Though, they don't need to
be bound. God longs to give us far more than we can even now imagine, much
less dare to ask for.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      